@font-face {
    font-family: 'Fast Font';
    src: url(../fonts/icomoon.eot);
    src: url(../fonts/icomoon.eot#iefix) format("embedded-opentype"), url(../fonts/icomoon.ttf) format("truetype"), url(../fonts/icomoon.woff) format("woff"), url(../fonts/icomoon.svg#icomoon) format("svg");
    font-weight: normal;
    font-style: normal;
}

.fast-icon-font {
    font-family: "Fast Font", var(--font-family-sans-serif) !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.game-area {
    border-radius: 12px;
    border: 1px solid #353d48;
    position: relative;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center
}

.hilo-main {
    justify-content: center !important;
    position: relative;
    text-align: center;
    overflow: hidden;
    margin-top: 15px;
}

.hilo-main .hilo-deck {
    width: 100%;
}

.hilo-main .hilo-deck>.hilo-deck-card {
    border-radius: 24px;
}

.hilo-main .hilo-deck .placeholder-card:nth-child(1) {
    bottom: 4px;
}

.hilo-main .hilo-deck .placeholder-card:nth-child(2) {
    bottom: 8px;
}

.hilo-main .hilo-deck .placeholder-card:nth-child(3) {
    bottom: 12px;
}

.hilo-main .hilo-deck .placeholder-card:nth-child(4) {
    bottom: 16px;
}

.hilo-main .hilo-deck .placeholder-card:nth-child(5) {
    bottom: 20px;
}

.hilo-main .hilo-deck .hilo-deck-card {
    width: 130px;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-perspective: 1000px;
    perspective: 1000px;
    position: relative;
    margin: 0 auto;
    z-index: 8;
    -webkit-animation: fadeIn .3s;
    animation-timing-function: linear;
}

.hilo-deck-card:before {
    content: " ";
    background: url(../images/back3.svg) no-repeat center;
    background-size: 100%;
    -webkit-filter: contrast(1.2);
    filter: contrast(1.2);
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(70px)
}

.hilo-deck-card:after {
    content: " ";
    background: url(../images/back3.svg) no-repeat center;
    background-size: 100%;
    -webkit-filter: contrast(1.2);
    filter: contrast(1.2);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: translateX(-70px);
}

.hilo-sign {
    margin-top: 45px;
}

.hilo-sign img {
    pointer-events: none;
}

.hilo-countdown-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) scale(-1, 1);
    transform: translate(-50%, -50%) scale(-1, 1);
}

.hilo-countdown {
    -webkit-transform-origin: center center;
    transform-origin: center center;
}

.hilo-main .hilo-deck-card .card-back, .hilo-main .hilo-deck-card .card-front {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 6px;
}

.hilo-main .hilo-deck-card .card-back {
    background: url(../images/back2.svg) no-repeat center;
    background-size: 100%;
    box-shadow: 0 1px 2px #111;
    filter: contrast(1.2);
    transform: rotateY(360deg);
}

.hilo-form .btn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0;
}

.hilo-main .hilo-deck-card .card-front {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    background: transparent;
}

.hilo-main .hilo-flipper {
    position: relative;
    height: 238px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: center center;
    transform-origin: center center;
    transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
}

.hilo-main .hilo-card {
    font-family: 'Fast Font', Exo\ 2, Open Sans,sans-serif;
    position: relative;
}

.hilo-card_diamonds, .hilo-card_red {
    color: #e86376;
}

.hilo-card_spades, .hilo-card_black {
    color: #2b2f3b;
}

.hilo-card_joker {
    background-image: linear-gradient(to right, #0062f4, #009ddd);
}

.hilo-card_joker .hilo-sign {
    font-size: 0.65em;
}

.hilo-main .hilo-label {
    /*font-size: 45px;*/
    /*position: absolute;*/
}

.hilo-main .hilo-label_top {
    /*top: 1rem;*/
    /*left: 2rem;*/
}

.hilo-main .hilo-label_bottom {
    bottom: 1.2rem;
    right: 2rem;
}

.hilo-history {
    display: flex;
    overflow: hidden;
    width: 100%;
    position: relative;
    padding-bottom: 1.2rem;
    margin-bottom: 1rem;
}

.hilo-history-feed {
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.hilo-history-feed__comparison-sign_lo {
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
}

.hilo-history-feed-sign {
    display: block;
}

.empty-hilo {
    opacity: .1;
    text-align: center;
}

.empty-hilo img {
    width: 76px;
}

.hilo-card_sm {
    width: 60px;
    height: 90px;
    flex-shrink: 0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 600;
}

.hilo-card-num {
    line-height: normal;
}

.hilo-history-card {
    margin-right: 18px;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity .7s;
}

.hilo-history-card:hover {
    opacity: 1 !important;
}

.hilo-card_joker {
    background-image: linear-gradient(to right, #0062f4, #009ddd) !important;
    font-size: 26px;
}

.hilo-card {
    background-image: linear-gradient(to top, #ffffff, #ffffff);
    /*font-family: 'Fast Font', Exo\ 2, Open Sans, sans-serif;*/
    /*cursor: pointer;*/
    position: relative;
    margin-right: 5px;
}

.hilo-history-card_zero-width .hilo-history-feed {
    opacity: 0;
}

.hilo-history-feed {
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: opacity .7s;
}

.hilo-history-feed-sign {
    display: block;
}

.hilo-history-feed-sign_lo {
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
}

.hilo-bet {
    padding: 5px 0;
}

.hilo-bet,.hilo-bet .btn-bet {
    display: -webkit-flex;
    display: flex
}

.hilo-bet .btn-bet {
    font-size: 12px;
    overflow: hidden;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    margin-right: 8px;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
    text-align: left;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 6px;
    background: #4986f5;
    opacity: .5;
}

.hilo-bet .btn-bet:focus,.hilo-bet .btn-bet:hover {
    outline: none
}

.hilo-bet .btn-bet:hover, .hilo-bet .btn-bet.isActive {
    opacity: 1;
}

.hilo-bet .btn-bet:last-child {
    margin-right: 0
}

.hilo-bet .btn-bet.bet-high {
    color: #d7a2ff;
    background: #a100f4;
}

.hilo-bet .btn-bet.bet-high .bet-chance {
    background: rgba(31,85,183,.18)
}

.hilo-bet .btn-bet.bet-high .bet-chance .chance-icon {
    color: #d7a2ff;
}

.hilo-bet .btn-bet.bet-low {
    color: #625622;
    background: #ffd000
}

.hilo-bet .btn-bet.bet-low .bet-chance {
    background: rgba(142,118,14,.18);
}

.hilo-bet .btn-bet.bet-low .bet-chance .chance-icon {
    color: #625622;
}

.hilo-bet .btn-bet.bet-red {
    color: #67110b;
    background: #e86376;
}

.hilo-bet .btn-bet.bet-red .bet-chance {
    background: rgba(136, 29, 44, 0.18)
}

.hilo-bet .btn-bet.bet-black {
    color: #5e6a7f;
    background: #333a48;
}

.hilo-bet .btn-bet.bet-black .bet-chance {
    background: #2f3542
}

.hilo-bet .btn-bet.bet-numb, .hilo-bet .btn-bet.bet-letr, .hilo-bet .btn-bet.bet-ka, .hilo-bet .btn-bet.bet-a {
    color: #256b20;
    background: #62ca5b;
}

.hilo-bet .btn-bet.bet-numb .bet-chance, .hilo-bet .btn-bet.bet-letr .bet-chance, .hilo-bet .btn-bet.bet-ka .bet-chance, .hilo-bet .btn-bet.bet-a .bet-chance {
    background: #43b73b
}

.hilo-bet .bet-chance {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex: none;
    flex: none;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
    width: 100%;
    padding: 4px 8px;
    background: rgba(0,0,0,.08);
}

.hilo-bet .bet-chance .chance-icon {
    margin: 4px 0 7px;
    color: #1f55b7
}

.hilo-bet .bet-chance .chance-icon svg {
    width: 20px;
    height: 20px;
    display: block
}

.hilo-bet .bet-chance .chance-text {
    line-height: 16px;
    text-align: center;
    font-weight: 600;
}

.hilo-bet .bet-chance .chance-text span {
    font-size: 17px;
    font-weight: 600
}

.hilo-bet .bet-text {
    font-size: 14px;
    font-weight: 600;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    width: 100%;
    height: 24px;
    padding: 0;
}

@media screen and (max-width: 820px) {
    .hilo-bet .bet-chance {
        padding: 5px
    }

    .hilo-bet .bet-text {
        font-size: 15px;
        height: 33px
    }
}

.bet-block {
    margin-bottom: 15px;
    padding: 8px;
    border: 1px solid hsla(0,0%,59.2%,.12);
    border-radius: 6px
}

@media screen and (max-width: 1170px) {
    .game-area {
        border:0
    }

    .bottom-corners, .top-corners {
        display: none
    }
}
.flip {
    -webkit-animation: flipCard 1s;
    transform: rotateY(180deg);
}
@-webkit-keyframes flipCard {
    0% {
        -webkit-transform: rotateY(0deg);
    }
    10% {
        -webkit-transform:rotateY(0deg);
    }
    20% {
        -webkit-transform: rotateY(0deg);
    }
    30% {
        -webkit-transform: rotateY(0.17135717260665195deg);
    }
    40% {
        -webkit-transform:rotateY(5.4834295234128625deg);
    }
    50% {
        -webkit-transform: rotateY(41.639792943416424deg);
    }
    60% {
        -webkit-transform: rotateY(138.36020705658356deg);
    }
    70% {
        -webkit-transform: rotateY(174.51657047658713deg);
    }
    80% {
        -webkit-transform: rotateY(179.82864282739334deg);
    }
    90% {
        -webkit-transform:rotateY(180deg);
    }
    100% {
        -webkit-transform: rotateY(180deg);
    }
}
@-moz-keyframes flipCard {
    0% {
        -moz-transform: rotateY(0deg);
    }
    10% {
        -moz-transform:rotateY(0deg);
    }
    20% {
        -moz-transform: rotateY(0deg);
    }
    30% {
        -moz-transform: rotateY(0.17135717260665195deg);
    }
    40% {
        -moz-transform:rotateY(5.4834295234128625deg);
    }
    50% {
        -moz-transform: rotateY(41.639792943416424deg);
    }
    60% {
        -moz-transform:rotateY(138.36020705658356deg);
    }
    70% {
        -moz-transform: rotateY(174.51657047658713deg);
    }
    80% {
        -moz-transform: rotateY(179.82864282739334deg);
    }
    90% {
        -moz-transform:rotateY(180deg);
    }
    100% {
        -moz-transform: rotateY(180deg);
    }
}
@-ms-keyframes flipCard {
    0% {
        -ms-transform:  rotateY(0deg);
    }
    10% {
        -ms-transform:rotateY(0deg);
    }
    20% {
        -ms-transform: rotateY(0deg);
    }
    30% {
        -ms-transform: rotateY(0.17135717260665195deg);
    }
    40% {
        -ms-transform:rotateY(5.4834295234128625deg);
    }
    50% {
        -ms-transform: rotateY(41.639792943416424deg);
    }
    60% {
        -ms-transform: rotateY(138.36020705658356deg);
    }
    70% {
        -ms-transform: rotateY(174.51657047658713deg);
    }
    80% {
        -ms-transform: rotateY(179.82864282739334deg);
    }
    90% {
        -ms-transform:rotateY(180deg);
    }
    100% {
        -ms-transform: rotateY(180deg);
    }
}
@-o-keyframes flipCard {
    0% {
        -o-transform: rotateY(0deg);
    }
    10% {
        -o-transform:rotateY(0deg);
    }
    20% {
        -o-transform: rotateY(0deg);
    }
    30% {
        -o-transform: rotateY(0.17135717260665195deg);
    }
    40% {
        -o-transform:rotateY(5.4834295234128625deg);
    }
    50% {
        -o-transform: rotateY(41.639792943416424deg);
    }
    60% {
        -o-transform:rotateY(138.36020705658356deg);
    }
    70% {
        -o-transform: rotateY(174.51657047658713deg);
    }
    80% {
        -o-transform: rotateY(179.82864282739334deg);
    }
    90% {
        -o-transform:rotateY(180deg);
    }
    100% {
        -o-transform: rotateY(180deg);
    }
}
@keyframes flipCard {
    0% {
        transform: rotateY(0deg);
    }
    10% {
        transform: rotateY(0deg);
    }
    20% {
        transform: rotateY(0deg);
    }
    30% {
        transform: rotateY(0.17135717260665195deg);
    }
    40% {
        transform: rotateY(5.4834295234128625deg);
    }
    50% {
        transform: rotateY(41.639792943416424deg);
    }
    60% {
        transform: rotateY(138.36020705658356deg);
    }
    70% {
        transform: rotateY(174.51657047658713deg);
    }
    80% {
        transform:  rotateY(179.82864282739334deg);
    }
    90% {
        transform: rotateY(180deg);
    }
    100% {
        transform: rotateY(180deg);
    }
}

.rightDirection {
    -webkit-transform: rotate(-40deg) translate(100vw,-50vh);
    transform: rotate(-40deg) translate(100vw,-50vh);
    -webkit-animation: reorderRight .7s;
    animation: reorderRight .7s
}

@-webkit-keyframes reorderRight {
    0% {
        -webkit-transform: rotate(0deg) translate(0);
        transform: rotate(0deg) translate(0)
    }

    to {
        -webkit-transform: rotate(-40deg) translate(100vw,-50vh);
        transform: rotate(-40deg) translate(100vw,-50vh)
    }
}

@keyframes reorderRight {
    0% {
        -webkit-transform: rotate(0deg) translate(0);
        transform: rotate(0deg) translate(0)
    }

    to {
        -webkit-transform: rotate(-40deg) translate(100vw,-50vh);
        transform: rotate(-40deg) translate(100vw,-50vh)
    }
}

.isAnimate {
    -webkit-animation: shiftFromLeft .3s linear;
    animation: shiftFromLeft .3s linear
}

.hilo-footer {
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    height: 131px;
    padding: 10px 20px;
    -webkit-transition: opacity .3s;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: opacity .3s;
    background: rgb(33 45 64 / 28%);
    border-radius: 9px;
}

@media (max-width: 820px) {
    .hilo-footer {
        padding:10px 10px 0;
    }
}

@media (max-width: 1024px) {
    .hilo-statistics__cards div {
        transform: scale(0.8);
    }

    .hilo-main .hilo-flipper {
        height: 200px;
    }

    .hilo-main .hilo-deck .hilo-deck-card {
        width: 130px;
    }
}

.hilo-statistics__card-label {
    color: #FFF;
    font-size: 13px;
    text-align: center;
    width: 60px;
    margin-top: 3px;
    background: #dc3545;
    border-radius: 5px;
}

.hilo-statistics__card-frequency-progress {
    background-color: #000000;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: height .3s;
}

.hilo-statistics__cards {
    width: 100%;
    min-height: 62px;
    position: relative;
    display: -webkit-flex;
    display: flex;
    float: left;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform .6s ease-in-out;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out,-webkit-transform .6s ease-in-out;
    overflow: overlay;
}

.hilo-statistics-region {
    padding: 10px 20px;
    width: 100%;
}

.hilo-statistics-region .hilo-statistics-top {
    align-items: center !important;
    justify-content: space-between !important;
    display: flex !important;
}

.hilo-statistics-region .hilo-statistics-top .hilo-stat_title {
    font-size: 13px;
    color: #fff;
}

.hilo-statistics-region .hilo-statistics-top .hilo-statistics-colors-ratio {
    border-radius: 0.25rem;
    height: 17px;
    overflow: hidden;
    display: flex;
    font-size: 12px;
    flex-grow: 1;
    margin-left: 1rem;
}

.hilo-statistics-region .hilo-statistics-top .hilo-statistics-colors-ratio .ratio__red, .hilo-statistics-region .hilo-statistics-top .hilo-statistics-colors-ratio .ratio__black {
    height: 100%;
    text-align: center;
    transition: width .3s;
}

.hilo-statistics-region .hilo-statistics-top .hilo-statistics-colors-ratio .ratio__red {
    background: #e86376;
}

.hilo-statistics-region .hilo-statistics-top .hilo-statistics-colors-ratio .ratio__black {
    background: #1c2028;
}

.empt {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #FFF;
}